import { ICreateAssistantValues, IUser } from "../types/interfaces";

export const adminService = {
	/**
	 * Creates an assistant with the given values.
	 *
	 * @param values - The values for creating the assistant.
	 * @returns A promise that resolves to the created assistant.
	 * @throws An error if the creation of the assistant fails.
	 */
	createAssistant: async (
		token: string | null,
		values: ICreateAssistantValues,
	) => {
		const { name, image, description, jobTitle, basePrompt } = values;
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/admin/create-assistant`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					name,
					image,
					description,
					jobTitle,
					basePrompt,
				}),
			},
		);
		if (!response.ok) {
			throw new Error("Failed to create assistant");
		}
		return response.json();
	},

	/**
	 * Creates a new user.
	 *
	 * @param values - The user object containing the name, email, and business profile.
	 * @returns A promise that resolves to the response JSON data.
	 * @throws An error if the request to create the user fails.
	 */
	createUser: async (token: string | null, values: IUser) => {
		const { name, email, businessProfile } = values;
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/create-user`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					name,
					email,
					businessProfile,
				}),
			},
		);
		if (!response.ok) {
			throw new Error("Failed to create user");
		}
		const data: any = await response.json();
		return data.redirectUrl;
	},

	/**
	 * Updates an assistant with the given values.
	 *
	 * @param values - The values to update the assistant with.
	 * @param assistantId - The ID of the assistant to update.
	 * @returns A promise that resolves to the updated assistant.
	 * @throws An error if the update fails.
	 */
	updateAssistant: async (
		token: string | null,
		values: ICreateAssistantValues,
		assistantId: string | undefined,
	) => {
		const {
			name,
			image,
			description,
			jobTitle,
			basePrompt,
			relatedAssistants,
		} = values;
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/admin/update-assistant/${assistantId}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					name,
					image,
					description,
					jobTitle,
					basePrompt,
					relatedAssistants,
				}),
			},
		);
		if (!response.ok) {
			throw new Error("Failed to update assistant");
		}
		return response.json();
	},

	getAllUsers: async (token: string | null) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/admin/get-users`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		if (!response.ok) {
			throw new Error("Failed to get users");
		}
		return response.json();
	},

	getOneUsers: async (token: string | null, userId: string) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/admin/get-user/${userId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);
		if (!response.ok) {
			throw new Error("Failed to get users");
		}
		return response.json();
	},

	updateUser: async (token: string | null, values: any, id: string) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/admin/update-user/${id}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			},
		);
		if (!response.ok) {
			throw new Error("Failed to update user");
		}
		return response.json();
	},
	createPrompt: async (token: string | null, values: any, id: string) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/admin/add-prompt/${id}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			},
		);
		if (!response.ok) {
			throw new Error("Failed to update user");
		}
		return response.json();
	},
};
