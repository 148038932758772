import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal"; // Ensure this import path is correct
import InfoIcon from '@mui/icons-material/Info';
import NewlineText from "./NewlineText";

interface SquareProps {
  title: string;
  description?: string;
  _id?: string;
  handleClick?: (_id: string) => void;
}

const Square: React.FC<SquareProps> = ({ title, description, _id, handleClick }) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);  // State to manage modal visibility

  const squareClass = `
        relative
        bg-brand-logo
        w-full
        h-3/4
        text-center
        rounded-3xl
        shadow-lg
        text-brand-green
        flex
        flex-col
        justify-center
        items-center
        hover:bg-brand-cream
        hover:text-brand-green
        hover:shadow-2xl
        hover:scale-110
        active:text-brand-green-logo
        
        active:cursor-pointer
        p-2
    `;

  return (
    <motion.div
      className={`${squareClass} dash-step-1`}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.7 }}
      onClick={() => {
        if (_id && !handleClick) {
          navigate(`/assistant/${_id.toString()}`);
        }
      }}
    >
      <div className="flex w-full absolute top-2 left-3 dash-step-2">
        <motion.div
          onClick={(e) => {
            e.stopPropagation(); // Prevent this click from triggering parent's onClick
            setModalOpen(true);  // Opens the modal
          }}
          animate={{ scale: [1, 1.2, 1] }}
          transition={{
            repeat: 2,
            duration: 2,
            ease: "easeInOut",
          }}
          style={{ transformOrigin: "center top" }}
        >
          <InfoIcon
            sx={{
              color: '#FFE7C3', // Set the icon color
              backgroundColor: '#00BF63', // Set the background color
              borderRadius: '50%', // Make it round
              fontSize: '22px', // Adjust size if necessary
              cursor: 'pointer', // Make it clickable
            }}
          />
        </motion.div>
      </div>
      <h3 className="text-lg md:text-2xl transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer ">{title}</h3>

      {/* Modal component should be outside of the motion.div but still within the component structure */}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <h3 className="text-lg md:text-2xl text-brand-green">{title}</h3>
          <NewlineText text={description} />
        </Modal>
      )}
    </motion.div>
  );
};

export default Square;
