import { useAuth } from "@clerk/clerk-react";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import LoadingChatBubble from "../components/loadingChatBubble";
import { service } from "../services";

interface IChatContextState {
  selectedChat: any;
  setSelectedChat: (chat: any) => void;
  selectChat: (chatId: string) => void;
  allChats: any;
  selectedAssistant: any;
  setSelectedAssistant: (assistantId: string) => void;
  sendChat: (prompt: string, room: string) => void;
  createChat: () => void;
  isMessageLoading: boolean;
  getAllChats: () => void;
  question: string;
  sendQuestion: (prompt: string) => void;
  prompts: IPrompt[];
}

interface IPrompt {
  display: string;
  prompt: string;
  _id: string;
}
const ChatContext = createContext<IChatContextState | undefined>(undefined);

export const useChat = () => useContext(ChatContext)!;

interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const { getToken } = useAuth();
  const [allChats, setAllChats] = useState<any[]>([]);
  const [selectedAssistant, setSelectedAssistant] = useState<
    string | undefined
  >(undefined);
  const [selectedChat, setSelectedChat] = useState<any>(undefined);
  const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const [prompts, setPrompts] = useState<IPrompt[]>([]);

  const selectChat = async (chatId: string) => {
    const token = await getToken();
    const response = await service.chatService.getChatById(token, chatId);
    setSelectedChat(response);
    console.log(response);
    // if (response.messages.length > 0) {
    // 	console.log("\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nn\n\n\n\n\n\n\n\nn\\n\n\n");
    // 	getPrompts();
    // }
  };

  const sendChat = async (prompt: string, room: string) => {
    setIsMessageLoading(true);
    const token = await getToken();
    setQuestion(prompt);

    let chatId;
    if (!selectedChat) {
      const chat = await createChat();
      chatId = chat._id;
    }

    const tempMessageId = Date.now().toString();

    const optimisticMessage = {
      _id: tempMessageId,
      question: prompt,
      component: <LoadingChatBubble />,
      status: "pending",
    };

    setSelectedChat((currentChat: any) => ({
      ...currentChat,
      messages: [...currentChat.messages, optimisticMessage],
    }));

    try {
      console.log({ prompt, chatId, room });
      const response = await service.chatService.sendMessage(
        token,
        prompt,
        chatId ?? selectedChat._id, // ! We need top handle null values to create a new chat
        room
      );

      if (response && response.response) {
        setSelectedChat((currentChat: any) => {
          const updatedMessages = currentChat.messages.map((message: any) =>
            message._id === tempMessageId
              ? {
                  ...message,
                  answer: response.response,
                  status: "completed",
                  component: undefined,
                }
              : message
          );
          return { ...currentChat, messages: updatedMessages };
        });
      } else {
        throw new Error("Invalid response received");
      }
    } catch (error) {
      console.error("Error sending chat message:", error);
      setSelectedChat((currentChat: any) => {
        const updatedMessages = currentChat.messages.map((message: any) =>
          message._id === optimisticMessage._id
            ? { ...message, answer: "Failed to get response", status: "failed" }
            : message
        );
        return { ...currentChat, messages: updatedMessages };
      });
    } finally {
      setIsMessageLoading(false);
    }
  };

  const getPrompts = async () => {
    const token = await getToken();
    if (!token || !selectedAssistant) return;
    const assistant = await service.assistantService.getAssistant(
      token,
      selectedAssistant
    );
    console.log(assistant);
    setPrompts(assistant.prompts);
  };

  const createChat = async () => {
    const token = await getToken();
    if (!token) {
      console.error("Token or Assistant ID is missing");
      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/chats/create-chat`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ assistantId: selectedAssistant }),
      }
    );
    const data = await response.json();
    setAllChats((prev) => [...prev, data]);
    setSelectedChat(data);
    return data;
  };

  const getAllChats = async () => {
    const token = await getToken();
    console.log(selectedAssistant);
    const chats = await service.chatService.getChatsByAssistant(
      token,
      selectedAssistant
    );
    setAllChats(chats);
    console.log(chats);
  };

  const sendQuestion = (prompt: string) => {
    setQuestion(prompt);
    sendChat(prompt, "defaultRoom"); // Replace 'defaultRoom' with your actual room logic if needed
  };

  useEffect(() => {
    getAllChats();
    setSelectedChat(undefined);
    getPrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssistant]);

  return (
    <ChatContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        selectChat,
        allChats,
        selectedAssistant,
        setSelectedAssistant,
        sendChat,
        createChat,
        isMessageLoading,
        getAllChats,
        question,
        sendQuestion,
        prompts,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
