import { useUser } from "@clerk/clerk-react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import React, { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import "../../../App.css";
import { useChat } from "../../../context/ChatContext";
import Icon from "../../Icons/Icon";
import Markdown from "./Markdown";
import TextInput from "./TextInput";
import { useTheme } from "./ThemeContext"; // Adjust the import path as necessary

interface IMessage {
  _id: string;
  question: string;
  answer?: string;
  component?: React.ReactNode;
}

const ChatWindow = () => {
  const {
    selectedChat,
    setSelectedChat,
    createChat,
    question,
    sendChat,
    prompts,
    isMessageLoading,
  } = useChat();
  const { user } = useUser();
  const { theme } = useTheme();
  const [response, setResponse] = useState<string>("");
  const [isUserScrolling, setIsUserScrolling] = useState<boolean>(false);
  const [showPrompts, setShowPrompts] = useState<boolean>(true);
  let room: string = Math.round(Math.random() * 100000).toString();
  const hiddenTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_API_BASE_URL ?? "");

      socketRef.current.on("openai_response", (fullResponse) => {
        const cleanedResponse = fullResponse
          .replace(/```markdown|```/g, "")
          .trim();
        setSelectedChat((currentChat: any) => {
          if (!currentChat) return currentChat;
          let newMessages = currentChat.messages.slice();
          newMessages.pop();
          const newMessage = {
            _id: Date.now().toString(),
            question: question,
            answer: cleanedResponse ? cleanedResponse : "",
          };
          return {
            ...currentChat,
            messages: [...newMessages, newMessage],
          };
        });
      });

      socketRef.current.on("stream_end", (fullResponse) => {
        const cleanedResponse = fullResponse
          .replace(/```markdown|```/g, "")
          .trim();
        setResponse(cleanedResponse);
        setSelectedChat((currentChat: any) => {
          if (!currentChat) return currentChat;
          let newMessages = currentChat.messages.slice();
          newMessages.pop();
          const newMessage = {
            _id: Date.now().toString(),
            question: question,
            answer: cleanedResponse,
          };
          return {
            ...currentChat,
            messages: [...newMessages, newMessage],
          };
        });
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [question, setSelectedChat]);

  useEffect(() => {
    if (selectedChat && socketRef.current) {
      socketRef.current.emit("joinRoom", selectedChat._id as string);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (isMessageLoading) {
      setResponse("");
    }
  }, [isMessageLoading]);

  const handlePromptClick = (prompt: string) => {
    sendChat(prompt, room);
    setShowPrompts(false);
  };

  const removeMarkdown = (text: string) => {
    return text
      .replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/g, "$1") // Remove bold, italic, and links
      .replace(/[*_`~>]/g, ""); // Remove other markdown symbols
  };

  const handleCopyClick = (text: string) => {
    const plainText = removeMarkdown(text);
    if (hiddenTextAreaRef.current) {
      hiddenTextAreaRef.current.value = plainText;
      hiddenTextAreaRef.current.select();
      document.execCommand("copy");
    }
  };

  const handleUserScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current!;
    if (scrollHeight - scrollTop > clientHeight + 50) {
      setIsUserScrolling(true);
    } else {
      setIsUserScrolling(false);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      setResponse("");
    }
  };

  const questionClass = `mb-2 text-[16px] mb-6 ${
    theme === "dark" ? "text-brand-green-dark" : "text-brand-green-dark"
  }`;

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (!isUserScrolling) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedChat?.messages, isUserScrolling]);

  useEffect(() => {
    if (selectedChat) {
      setShowPrompts(false);
    }
  }, [selectedChat]);

  return (
    <div
      className="flex flex-col w-full max-w-full overflow-hidden items-center"
      style={{ height: "calc(100vh - 200px)" }}
    >
      <div
        className="flex-1 overflow-auto hide-scrollbar w-full"
        ref={chatContainerRef}
        onScroll={handleUserScroll}
      >
        {selectedChat?.messages.map((message: IMessage, index: number) => (
          <div className="w-full max-w-[800px] mx-auto my-8" key={message._id}>
            <div className="flex items-start">
              <img
                src={user?.imageUrl}
                alt="profile"
                className="mr-2"
                style={{ width: "24px", height: "24px", borderRadius: "50%" }}
              />
              <div className="flex flex-col mb-4">
                <p
                  className={`${
                    theme === "dark"
                      ? "text-brand-logo"
                      : "text-brand-green-dark"
                  } text-[16px] font-bold`}
                >
                  You
                </p>
                <p className={questionClass}>
                  {message?.question?.split("\n")?.map((question, idx) => (
                    <div key={idx}>
                      {question}
                      <br />
                    </div>
                  ))}
                </p>
              </div>
            </div>
            {message.component ? (
              <Markdown data={response} />
            ) : (
              <div className="flex items-start">
                <Icon name="ai" className="mr-2 -mt-4" />
                <div className="flex flex-col">
                  <p
                    className={`${
                      theme === "dark" ? "text-brand-logo" : "text-brand-green"
                    } text-[16px] font-bold mb-2`}
                  >
                    sloane
                  </p>
                  <Markdown data={message.answer ?? ""} />
                  <div className="w-full justify-center items-center ml-[40.5%]">
                    <button
                      className="text-brand-green-dark text-sm underline mt-2 transform hover:scale-[115%] ease-in-out duration-500 text-center"
                      onClick={() => handleCopyClick(message.answer ?? "")}
                    >
                      Copy Response
                    </button>
                  </div>
                </div>
              </div>
            )}
            {index === selectedChat?.messages.length - 1 && (
              <div ref={messagesEndRef} />
            )}
          </div>
        ))}
      </div>

      {/* Prompts */}
      {showPrompts && (
        <div className="flex flex-wrap justify-center py-4 w-[70%] step-2">
          {prompts.map((prompt) => (
            <button
              key={Math.random()}
              className="mx-2 p-2 mb-2 bg-brand-cream text-brand-green text-[12px] rounded-[100px] hover:text-brand-green-dark shadow-sm transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer border-[1px] border-brand-green"
              onClick={() => handlePromptClick(prompt.prompt)}
            >
              {prompt.display}
            </button>
          ))}
        </div>
      )}

      <button
        className=" mb-3 text-brand-green-dark text-[12px] rounded-[100px] transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer step-3"
        onClick={() => setShowPrompts(!showPrompts)}
      >
        {showPrompts ? "Hide Prompts" : "Show Prompts"}
      </button>

      {selectedChat ? (
        <div className="w-[95%] h-contain justify-center items-center xl:px-32 mb-2">
          <TextInput room={room} />
        </div>
      ) : (
        <div
          onClick={() => {
            createChat();
            setShowPrompts(true);
          }}
          className="absolute top-1/2 left-1/2 ml-4 border-brand-cream hidden md:block px-4 py-2 rounded-[50px] border-[1px] cursor-pointer"
        >
          <p
            className={`text-2xl font-thin flex items-center mb-0 step-1 ${
              theme === "dark" ? "text-brand-cream" : "text-brand-blue"
            }`}
          >
            <ArrowBackRoundedIcon className="w-10 h-8 " />
            Click New Chat To Begin
          </p>
        </div>
      )}
      <textarea
        ref={hiddenTextAreaRef}
        style={{ position: "absolute", left: "-9999px", top: "0" }}
      />
    </div>
  );
};

export default ChatWindow;
