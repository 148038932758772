import { useNavigate } from "react-router-dom";
import { useChat } from "../../../context/ChatContext";
import { useTheme } from "./ThemeContext"; // Ensure correct import path
import Icon from "../../Icons/Icon";
import ChatPreview from "./ChatPreview";

interface IProps {
    setIsSidebarOpen: (value: boolean) => void;
}

const Sidebar = ({setIsSidebarOpen}: IProps) => {
	const navigate = useNavigate();
	const { allChats, createChat } = useChat();
	const { theme } = useTheme(); // Use the theme from context

	const button1Class = `text-[14px] ${
		theme === "dark" ? "bg-brand-logo" : "bg-brand-logo"
	} hover:bg-blue-700 text-brand-green font-bold py-2 px-4 rounded-[100px] mb-4 pr-16 hover:bg-brand-cream active:bg-brand-orange active:text-brand-blue flex justify-center hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105`;

	return (
		<div className="flex flex-col w-full items-start pt-28 px-4 h-[95%] overflow-y-auto sidebar-container">
			
			<button className={`${button1Class} step-6`} onClick={() => navigate("/dashboard")}>
				<div className="w-6 h-4">
					<Icon name="back" className="mr-2 text-brand-green -mt-[2px]" />
				</div>
				Dashboard
			</button>

			<button
				className={`${button1Class} step-7`}
				onClick={() => {
				createChat();
				// setIsSidebarOpen(false);
				}}
			>
				<div className="w-6 h-4">
					<Icon name="newChat" className="mr-2" />
				</div>
				<div>New Chat</div>
			</button>
			{/* Here we reverse the chats for rendering without altering the original array */}
			{allChats &&
				[...allChats]
					.reverse()
					.map((chat: any) => <ChatPreview key={chat._id} chat={chat} />)}
		
		</div>
	);
};

export default Sidebar;
