import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "./Modal";
import NewlineText from "./NewlineText";

interface Square2Props {
  title: string;
  description?: string;
  link?: string;
  _id?: string;
  handleClick?: (_id: string) => void;
}

const Square2: React.FC<Square2Props> = ({
  title,
  description,
  link,
  _id,
  handleClick,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSquareClick = () => {
    if (link) {
      navigate(link);
    }
  };

  const handleIconClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation(); // Stop the propagation to prevent the square's click event
    setModalOpen(true);
  };

  return (
    <motion.div
      className="relative bg-brand-logo w-full h-3/4 text-center rounded-3xl shadow-lg text-brand-green flex flex-col justify-center items-center hover:bg-brand-cream hover:text-brand-green hover:shadow-2xl hover:scale-110 hover:transition-all hover:border-2 active:border-3 active:border-brand-orange active:text-brand-logo active:underline active:cursor-pointer p-2 px-8 md:px-4"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.7 }}
      onClick={handleSquareClick}
    >
      <div className="flex w-full absolute top-2 left-3">
        <InfoIcon
          onClick={handleIconClick}
          sx={{
            color: '#FFEFD6', // Set the icon color
            backgroundColor: '#00BF63', // Set the background color
            borderRadius: '50%', // Make it round
            fontSize: '22px', // Adjust size if necessary
            cursor: 'pointer', // Make it clickable
          }}
        />
      </div>
      <h3 className="text-lg md:text-2xl transform transition-transform duration-300 ease-in-out hover:scale-110 hover:cursor-pointer hover:underline">{title}</h3>
      
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <h3 className="text-lg md:text-2xl text-brand-cream">{title}</h3>
          <NewlineText text={description} />
        </Modal>
      )}
    </motion.div>
  );
};

export default Square2;
