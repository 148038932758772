// src/config/tourSteps.ts
import { Step } from 'react-joyride';

export const tourStepsChat: Step[] = [
 
  {
    target: ".step-1",
    content: "Click to begin a new chat with Sloane's expert, or choose a Quick Prompt below.",
  },
  {
    target: ".step-2",
    content: "Select a Quick Prompt to begin chatting with Sloane's expert.",
  },
  {
    target: ".step-3",
    content: "Click to hide or show the Quick Prompts",
  },
  {
    target: ".step-4",
    content: "Toggle between light mode and dark mode for your colour preference.",
  },
  {
    target: ".step-5",
    content: "Quick Links is Sloane's build in workflow. Click to jump to a suggested Hub after you've finished in this current Hub.",
  },
  {
    target: ".step-6",
    content: "Back to access all Hubs on the Dashboard.",
  },
  {
    target: ".step-7",
    content: "Create a New Chat once you've already started chatting with Sloane's expert.",
  },
  {
    target: ".step-8",
    content: "Edit the name of your chat. Keeping things organised and easy to find.",
  },
  {
    target: ".step-9",
    content: "Delete unwanted chats. Once deleted, this cannot be undone.",
  },
  
  
  // Add more steps as needed
];