import { Link } from "react-router-dom";
import Square from "./Dashboard/Square";
import { useUser } from "@clerk/clerk-react";
import NotAdmin from "./NotAdmin";

function Admin() {
  const { user } = useUser();

  if (user?.publicMetadata.account !== "admin") {
    return <NotAdmin />;
  }

  const squareDiv =
    "w-[150px] h-[120px] md:w-[200px] md:h-[150px] lg:h-[150px] mx-8 lg:mx-8 relative leading-none";

  return (
    <div className="w-screen-1 min-h-screen max-h-contain bg-brand-green flex justify-center items-center">
      <div className="max-w-[1440px] w-full flex flex-col justify-between h-full">
        <div>
          <h2 className="text-brand-cream text-center text-4xl md:text-7xl lg:text-[112px] pt-16 h-[10%] lg:h-1/6">
            Founders Lounge
          </h2>
        </div>

        <div className="p-8 h-[10%] lg:h-1/6">
          <h5 className="font-Black text-brand-orange-cream text-brand-cream text-[24px] md:text-4xl leading-none">
            Hi Tobes,
          </h5>
          <h5 className="font-Black text-brand-cream text-[21px] md:text-4xl lg:mb-16 leading-none">
            What do you need today?
          </h5>
        </div>

        <div className="w-full h-[80%] lg:h-4/6 grid grid-cols-2 md:grid-cols-4 gap-2 justify-center items-center mx-auto -ml-5 md:-ml-0">
          <div className={squareDiv}>
            <Link to="/userform">
              <Square title="Create User" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/assistantform">
              <Square title="Create Ai Assistant" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/updateAssistant">
              <Square title="Update Ai Assistant" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/allUsers">
              <Square title="All users" />
            </Link>
          </div>
          <div className={squareDiv}>
            <Link to="/dashboard">
              <Square title="Your Dashboard" />
            </Link>
          </div>
          <div className={squareDiv}>
            {" "}
            {/* Add this block */}
            <Link to="/create-prompt">
              <Square title="Create Prompt" />
            </Link>
          </div>
          <div className={squareDiv}>
            {" "}
            {/* Add this block */}
            <Link to="/get-chats">
              <Square title="Get Chats" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
