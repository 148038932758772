import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { useChat } from "../../../context/ChatContext";
import Icon from "../../Icons/Icon";
import { useTheme } from "./ThemeContext";

interface IProps {
  room: string;
}

const TextInput: React.FC<IProps> = ({ room }) => {
  const { sendChat } = useChat();
  // Initialize Formik
  const formik = useFormik({
    initialValues: { question: "" }, // Initial values of the form
    onSubmit: (values, { resetForm }) => {
      // Handle form submission
      sendChat(values.question, room);
      formik.setFieldValue("question", "");
      resetForm();
    },
  });

  const { theme } = useTheme();

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className={`${
          theme === "dark" ? "bg-brand-logo" : "bg-brand-green"
        } rounded-[30px] px-4 mb-4 flex justify-center h-contain items-center text-brand-green py-[1px]`}
      >
        <TextField
          id="question"
          name="question"
          onChange={formik.handleChange}
          value={formik.values.question}
          placeholder="Ask a question..."
          className="bg-brand-blue-light w-full px-2 focus:outline-none text-brand-green overflow-y-auto max-h-[200px] lg:max-h-[400px] 1xl:max-h-[600px] text-wrap rounded-lg textarea1-scrollbar"
          multiline
          onKeyDown={(e) => {
            if (e.shiftKey && e.key === "Enter") {
              e.preventDefault();
              formik.setFieldValue("question", formik.values.question + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              formik.handleSubmit();
            }
          }}
          sx={{
            backgroundColor: "bg-brand-blue-light", // Assuming you have this color in your theme
            color: "#00BF63", // Apply text color
            ".MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent", // Removes the border
              },
              "&:hover fieldset": {
                borderColor: "transparent", // Removes the border on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent", // Removes the border when focused
              },
            },
            "& .MuiInputBase-input": {
              backgroundColor:
                theme === "dark" ? "bg-brand-logo" : "bg-brand-green", // Ensure text color matches your className color
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "transparent", // Removes the underline effect after clicking
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none", // Removes the underline effect before clicking
            },
            "&:hover .MuiInput-underline:before": {
              borderBottom: "none", // Ensure underline doesn't appear on hover
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "none", // Ensure underline doesn't appear on hover when not disabled
            },
            borderRadius: "rounded-md", // Apply border-radius if needed
            border: "none", // Additional border removal if needed
            outline: "none", // Removes the outline
          }}
        />
        <button type="submit" className="flex items-end justify-start">
          <div className="w-8 h-7">
            <Icon name="submit" />
          </div>
        </button>
      </div>
      {/* Optional: Add a submit button */}
    </form>
  );
};

export default TextInput;
