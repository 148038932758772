
import Tile from "./Tile";
import VideoTile from './VideoTile';
import Blogs from "./Files/Blogs.pdf";
import EmailFreebie from "./Files/EmailFreebie.pdf";
import NewOffering from "./Files/NewOffering.pdf";
import NewStaff from "./Files/NewStaff.pdf";
// import email from './Images/email.png';
// import marketing from './Images/marketing.png';

// Array of objects for each PDF
const pdfFiles = [
    { title: "Increase Social Audience", path: Blogs, description: "Streamline your marketing with one powerful message across all platforms! Transform a single blog post into consistent, engaging content for social media, website clicks, and email campaigns. Save time and maintain message consistency across every audience." },
    { title: "Email Database Growth", path: EmailFreebie, description: "Brainstorm & then transform ideas into action with the Free Download Workflow. Create irresistible freebies for your Home Page, market them on social media, and grow your email data-base while collecting valuable leads." },
    { title: "Create New Offering", path: NewOffering, description: "Increase your revenue stream with the New Offerings Workflow. From concept to launch, effortlessly create and market new products and paid services. Maximize your income potential and expand your business." },
    { title: "Training New Staff", path: NewStaff, description: "Wanting to expand? Streamline the process of training, interviewing, and hiring new staff. Simplify expansion efforts and ensure seamless team integration." }
];

// Array of objects for each Video
const videoFiles = [
  { title: "Sloane Tour", videoUrl: "https://youtu.be/_K7tuTrp41k?si=qSFXCPPkmSj27opO", backgroundImage: "https://i.ytimg.com/vi/_K7tuTrp41k/maxresdefault.jpg" },
  
  // Add more video files as needed
];

function Resources() {
  return (
    <div className="w-full h-screen bg-brand-green flex flex-col">
      <div>
        <h2 className="text-brand-cream text-center text-3xl md:text-7xl lg:text-[112px] xl:text-[132px] pt-2 lg:pt-2 mb-16">
          Resources
        </h2>
      </div>
      
      <div className="flex flex-col justify-center h-contain max-w-[1440px] mx-auto w-screen">
        <div className="px-8 pb-8">
          
          {/* Container for PDF Tiles */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-6 mb-8">
            {pdfFiles.map(pdf => (
              <div key={pdf.title} className="w-[150px] h-[120px] md:w-[150px] lg:w-[200px] md:h-[150px] lg:h-[150px] relative">
                <Tile title={pdf.title} description={<div dangerouslySetInnerHTML={{__html: pdf.description}} />} link={pdf.path} />
              </div>
            ))}
          </div>
          
          {/* Container for Video Tiles */}
           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-6 ">
            {videoFiles.map(video => (
              <div key={video.title} className="w-[200px] h-[180px] md:w-[250px] lg:w-[300px] md:h-[250px] lg:h-[200px] relative">
                <VideoTile title={video.title} videoUrl={video.videoUrl} backgroundImage={video.backgroundImage} />
              </div>
            ))}
          </div> 
          
        </div>
      </div>
    </div>
  );
}

export default Resources;
