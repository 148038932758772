import React, { useState, useEffect } from "react";
import { useAssistants } from "../../../hooks/useAssistants";
import Square from "./Square";
import Square2 from "./Square2";
import { IAssistant } from "../../../types/interfaces";
import { useUser } from "@clerk/clerk-react"; // Make sure to import useUser to access user information
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { tourSteps } from "../../../config/tourSteps"; // Import the tour steps

function DashboardContent() {
  const { assistants } = useAssistants();
  const { user } = useUser(); // Use the useUser hook to access user information
  const [run, setRun] = useState(false); // State for running the Joyride tour

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, type } = data;
    console.log("Joyride callback data:", data); // Added detailed logging
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED || (action === 'close' && type === 'step:after')) {
      localStorage.setItem("dashboardTourShown", "true");
      setRun(false);
      console.log("Tour finished or skipped, setting run to false");
    }
  };

  useEffect(() => {
    const tourShown = localStorage.getItem("dashboardTourShown");
    if (!tourShown) {
      console.log("Tour not shown yet, setting timer");
      const timer = setTimeout(() => {
        setRun(true);
        console.log("Timer finished, setting run to true");
      }, 2000); // 2-second delay to ensure all elements are rendered

      return () => {
        clearTimeout(timer);
        console.log("Timer cleared");
      };
    } else {
      console.log("Tour already shown");
    }
  }, []);

  useEffect(() => {
    console.log("run state:", run);
  }, [run]);

  return (
    <div className="w-full h-contain bg-brand-green">
      <Joyride
        steps={tourSteps}
        run={run}
        callback={handleJoyrideCallback}
        continuous
        showProgress
        showSkipButton

        styles={{
          options: {
            zIndex: 10000,
            arrowColor: '#FFE7C3', // Orange arrow color
            backgroundColor: '#FFE7C3',
            primaryColor: '#004B27',
            textColor: '#004B27',
          },
        }}
      />
      

      <div className="h-contain items-center w-[100%]">
        <div className="px-8 pb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-6">
            <div className="col-span-2 md:col-span-4 lg:col-span-4 pt-16 lg:pt-24">
              <h5 className="font-Black text-brand-orange-cream text-brand-cream text-[24px] md:text-5xl ">
                Hi {user?.firstName},
              </h5>
              <h5 className="font-Black text-brand-cream text-[21px] md:text-5xl">
                How can we help you today?
              </h5>
            </div>

            {/* Assistant squares */}
            {assistants &&
              assistants.map((assistant: IAssistant, index: number) => (
                <div
                  key={index}
                  className="w-[150px] h-[120px] md:w-[150px] lg:w-[200px] md:h-[150px] lg:h-[150px] relative"
                >
                  <Square
                    title={assistant.jobTitle}
                    description={assistant.description}
                    _id={assistant._id}
                  />
                </div>
              ))}

            <div className="w-[150px] h-[120px] md:w-[150px] lg:w-[200px] md:h-[150px] lg:h-[150px] relative">
              <Square2
                title="Resources"
                description="Need some help with implementing Ai? Jump into our resources for guidance with specific tasks."
                link="/resources"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardContent;
