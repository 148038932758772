import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { service } from "../../services";
import { useAuth } from "@clerk/clerk-react";
import EastIcon from "@mui/icons-material/East";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const UpdateUserForm: React.FC = () => {
  const { getToken } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (id) {
        const token = await getToken();
        const userResponse = await service.adminService.getOneUsers(token, id);
        setUserData(userResponse);
      }
    };
    fetchUserData();
  }, [id, getToken]);

  const handleSubmit = async (values: any, actions: any) => {
    try {
      const token = await getToken();
      await service.adminService.updateUser(token, values, id ? id : "");
      navigate("/allUsers");
    } catch (error) {
      console.error("Failed to submit form", error);
      actions.setSubmitting(false);
      setErrorModalOpen(true);
    }
  };

  const inputClass =
    "my-4 block w-full py-2 px-4 border border-brand-logo rounded-full bg-transparent text-brand-green-dark placeholder-green-700 focus:border-brand-green-dark outline-none";
  const inputClass2 =
    "my-4 block w-full px-4 py-3 border border-brand-logo rounded-[30px] bg-transparent text-brand-green-dark placeholder-green-700 scrollbar-hide focus:border-brand-green-dark outline-none h-[400px]";
  const labelClass = "block text-brand-logo font-bold mb-2";

  return (
    <div className="bg-brand-green flex justify-center items-center h-contain xl:h-screen w-full px-4 ">
      <div className="hidden lg:flex w-1/2 bg-brand-green justify-center items-center h-screen">
        <div className="w-3/5 h-full -ml-10">
          <img
            src="/images/signup.png"
            alt="Signup"
            className="object-contain w-full h-full "
          />
        </div>
        <div className="w-2/5 h-full flex items-center ml-4">
          <h1 className="leading-none text-brand-logo text-6xl">
            You've<br></br>made<br></br>{userData?.name || "User"}'s<br></br> biz easy.
          </h1>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-8 w-full lg:w-1/2 min-h-screen">
        <h2 className="text-xl lg:text-4xl font-bold mb-4 text-brand-cream w-full pt-20 md:pt-0">
          Update {userData?.name || "User"}'s Business Profile
        </h2>
        <Formik
          initialValues={{
            name: userData?.name || "",
            email: userData?.email || "",
            businessProfile: userData?.businessProfile || {
              businessName: "",
              businessType: "",
              businessSize: "",
              businessURL: "",
              businessDescription: "",
            },
          }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4 w-full">
              <div className="flex">
                <div className="flex flex-col w-1/2 pr-8">
                  <div>
                    <label htmlFor="name" className={labelClass}></label>
                    <Field
                      id="name"
                      name="name"
                      placeholder="First name*"
                      className={inputClass}
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className={labelClass}></label>
                    <Field
                      id="email"
                      name="email"
                      placeholder="Work email*"
                      type="email"
                      className={inputClass}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="businessProfile.businessName"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessName"
                      name="businessProfile.businessName"
                      placeholder="Business name*"
                      className={inputClass}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-1/2">
                  <div>
                    <label
                      htmlFor="businessProfile.businessType"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessType"
                      name="businessProfile.businessType"
                      placeholder="Profession*"
                      className={inputClass}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="businessProfile.businessSize"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessSize"
                      name="businessProfile.businessSize"
                      placeholder="Business size*"
                      type="number"
                      className={inputClass}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="businessProfile.businessURL"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessURL"
                      name="businessProfile.businessURL"
                      placeholder="URL*"
                      className={inputClass}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="businessProfile.businessDescription"
                  className={labelClass}
                ></label>
                <Field
                  as="textarea"
                  id="businessProfile.businessDescription"
                  name="businessProfile.businessDescription"
                  placeholder="Describe your business in 1-2 sentences*"
                  className={inputClass2}
                  multiline
                />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`flex items-center text-brand-green-dark py-2 px-4 rounded-md  justify-end w-full hover:text-brand-logo hover:underline ${
                    isSubmitting ? "opacity-50" : ""
                  } transition-colors duration-300 ease-in-out`}
                >
                  {isSubmitting ? "Submitting..." : "Continue"}
                  <span></span>
                  <EastIcon className="ml-2 hover:text-brand-logo text-brand-green-dark " />
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <Modal
          open={errorModalOpen}
          onClose={() => setErrorModalOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box className="bg-brand-cream p-4 rounded-lg shadow-lg max-w-sm mx-auto mt-32">
            <h2
              id="modal-title"
              className="text-xl font-Archivo mb-4 text-brand-green"
            >
              Form Submission Error
            </h2>
            <p id="modal-description">
              Please check that you have the following:
              <ul className="list-disc ml-6 my-4 gap-y-20">
                <li>
                  Email address in the right format (e.g. yourname@email.com)
                </li>
                <li className="my-4">
                  URL (e.g. www.yourbusiness.com) or type www.na.com if you
                  don't have a website
                </li>
                <li>All fields are filled in</li>
              </ul>
              Please email{" "}
              <span className="text-brand-green hover:underline">
                {" "}
                <a href="mailto:hello@sloane.biz">hello@sloane.biz</a>
              </span>{" "}
              if you continue to have issues.
            </p>
            <button
              onClick={() => setErrorModalOpen(false)}
              className="mt-4 bg-brand-green text-brand-cream py-2 px-4 rounded-full hover:bg-brand-green-dark hover:text-brand-logo transition duration-300"
            >
              Close
            </button>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default UpdateUserForm;
