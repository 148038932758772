// src/config/tourSteps.ts
import { Step } from 'react-joyride';

export const tourSteps: Step[] = [
 
  {
    target: ".dash-step-1",
    content: "These are called Hubs. Each Hub is an individually trained expert in its field.",
  },
  {
    target: ".dash-step-2",
    content: "The info icon gives you an overview of what the trained expert can help you with.",
  }
  // Add more steps as needed
];