import React from "react";
import { useSubscriptionStatus } from "../../../hooks/useSubscriptionStatus";
import PleaseSubscribe from "./PleaseSubscribe";
import DashboardContent from "./DashboardContent";
import { useAuth } from "@clerk/clerk-react"; // Make sure this import is correct

function Dashboard() {
	const { isSubscribed, isLoading } = useSubscriptionStatus();
	const { getToken } = useAuth(); // Correctly call useAuth here to get getToken

	const handleClickSubscribe = async () => {
		const token = await getToken(); // Await getToken call here, outside the fetch
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-checkout`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`, // Use token here
				},
			},
		);
		const data = await response.json();
		window.location.replace(data.url);
	};

	if (isLoading) {
		return (
			<div className="w-screen h-screen bg-brand-blue-dark flex justify-center items-center text-brand-orange"></div>
		);
	}

	return (
		<div className="w-screen-1 min-h-screen  bg-brand-green flex justify-center items-center">
			<div className="max-w-[1440px] w-full flex flex-col justify-between h-full ">
				{!isSubscribed ? (
					<PleaseSubscribe onClickSubscribe={handleClickSubscribe} />
				) : (
					<DashboardContent />
				)}
				{/* <DashboardContent /> */}
			</div>
		</div>
	);
}

export default Dashboard;

// import { useAuth } from "@clerk/clerk-react";
// import { useAssistants } from "../../../hooks/useAssistants";
// import { useSubscriptionStatus } from "../../../hooks/useSubscriptionStatus"; // Assuming you have this hook
// import { IAssistant } from "../../../types/interfaces";
// import Square from "./Square";
// import { useUser } from "@clerk/clerk-react";

// function Dashboard() {
// 	const { assistants } = useAssistants();
// 	const { isSubscribed } = useSubscriptionStatus(); // This hook checks the user's subscription status
// 	const { getToken } = useAuth();
// 	const { user } = useUser();

// 	const handleClickSubscribe = async () => {
// 		const response = await fetch(
// 			`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-checkout`,
// 			{
// 				method: "GET",
// 				headers: {
// 					Authorization: `Bearer ${await getToken()}`,
// 				},
// 			},
// 		);
// 		console.log(response);
// 		const data = await response.json();
// 		window.location.replace(data.url);
// 	};

// 	console.log(isSubscribed);

// 	return (
// 		<div className="w-screen h-contain md:h-screen bg-brand-blue-dark flex justify-center items-center">
// 			<div className="max-w-[1440px] w-full flex flex-col justify-between h-full">
// 				{!isSubscribed ? (
// 					// Display subscription message if not subscribed
// 					<div className="text-center">
// 						<h2 className="text-brand-orange-cream text-3xl md:text-7xl">
// 							You need to subscribe
// 						</h2>
// 						<p className="text-brand-cream mt-4">
// 							Access to our Ai Hub requires an active subscription.
// 						</p>
// 						{/* Include a link or button directing to the subscription page */}
// 						<button
// 							onClick={handleClickSubscribe}
// 							className="text-white mt-4 inline-block underline"
// 						>
// 							Subscribe Now
// 						</button>
// 					</div>
// 				) : (
// 					// Display the normal dashboard content if subscribed
// 					<>
// 						<div>
// 							<h2 className="text-brand-blue text-center text-3xl md:text-7xl lg:text-[112px] xl:text-[132px] pt-2 lg:pt-2 mb-16">
// 								Ai HUB
// 							</h2>
// 						</div>

// 						<div className="flex flex-col justify-center h-full">
// 							<div className="px-8 pb-8">
// 								<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-4 ">
// 									<div className="col-span-2 md:col-span-4 lg:col-span-4 pb-8">
// 										<h5 className="font-black text-brand-orange-cream text-brand-cream text-[24px] md:text-4xl">
// 											Hi {user?.firstName},
// 										</h5>
// 										<h5 className="font-black text-brand-orange-cream text-gray-500 text-[21px] md:text-4xl">
// 											How can we help you today?
// 										</h5>
// 									</div>
// 									{assistants &&
// 										assistants.map((assistant: IAssistant, index: number) => (
// 											<div
// 												key={index}
// 												className="w-[150px] min-h-[120px] md:w-[150px] lg:w-[200px] md:h-[150px] lg:h-[150px] relative"
// 											>
// 												<Square
// 													title={assistant.jobTitle}
// 													_id={assistant._id}
// 												/>
// 											</div>
// 										))}
// 								</div>
// 							</div>
// 						</div>
// 					</>
// 				)}
// 			</div>
// 		</div>
// 	);
// }

// export default Dashboard;
